import React from 'react';
import Mapa from '../../assets/images/mapa.png';
import { Cloud } from '../Cloud';

export function Place() {
  return (
    <div className="place h-pb--130 h-p-relative" id="evento">
      <Cloud color={'grey'} />
      <div className="container p-0">
        <div className="row mx-0">
          <div className="col-lg-5 p-0  m-top__30">
            <div className="festive-left">
              <div className="text-img"></div>
              <div className="text">
                <div>
                  <h1 className="text-title">¿Dónde?</h1>
                  <p className="para1">
                    <strong>
                      {' '}
                      <a href="https://goo.gl/maps/F7dXAbtqviYSJF5i6">Open PUCP</a>
                    </strong>{' '}
                    Boulevard Plaza Mantaro Pueblo Libre
                    <br />
                    Lima - Perú
                  </p>
                </div>

                <div>
                  <h1 className="text-title">¿Cuándo?</h1>
                  <p className="para1">Sábado 15 de Abril de 2023 - 9:00 AM</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 m-top__30">
            <img src={Mapa} alt="Open PUCP" />;
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Cloud } from '../Cloud';

export function Speaker(props) {
  let speaker = props.speaker.map((item) => {
    return (
      <div key={item.id} className="col-lg-4 col-md-4">
        <div className="speaker">
          <a className="h-d--flex" href={item.url_linkdin} target="_blank">
            <div className="img">
              <img src={item.img} alt={item.img} />
            </div>
            <div className="details">
              <h1 className="speaker-name">{item.name}</h1>
              {item.isAWS && (
                <p className="speakers">
                  <strong className="f-w__500">
                    {item.designation} <br />
                    {item.company}{' '}
                  </strong>{' '}
                </p>
              )}
              {!item.isAWS && (
                <p className="speakers">
                  {item.designation} <br />
                  {item.company}
                </p>
              )}
              <div className="speakericon">
                <span>Follow</span>
                <i className={item.linkdin}></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  });

  return (
    <div className="shedule-part h-pb--200 h-p-relative" id="speakers">
      <Cloud color={'white'} />
      <div className="overlay">
        <div className="title">
          <p>La lista final de speakers se anunciará en breve</p>
          <h1>Speakers</h1>
        </div>
        <div className="container">
          <div className="row">{speaker}</div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Navbar from '../components/navbar/navbar';
import { About } from '../components/about/about';
import Banner from '../components/banner/banner';
import Festival from '../components/festival/festival';
import { Gallery } from '../components/gallery/gallery';
import { Organization } from '../components/organization/organization';
import { Place } from '../components/place/place';
import Sponsor from '../components/sponsor/sponsor';
import Footer from '../components/footer/footer';
import {
  navitem,
  image,
  bgimg,
  bannerText,
  bannerCountdown,
  videobtn,
  festiveimg,
  festivedate,
  festivetext,
  location,
  speaker,
  organizer,
  galleryTitle,
  galleryImg,
  organizationimg,
  sponsorTitle,
  sponsorimg,
  footerbg,
} from '../components/variables/variable';
import { Speaker } from '../components/speaker/speaker';
import { Organizer } from '../components/organizer/organizer';

function home() {
  return (
    <div>
      <Navbar name={navitem} logo={image} />
      <Banner bg={bgimg} text={bannerText} countdown={bannerCountdown} videobtn={videobtn} />
      <About />
      <Festival img={festiveimg} year={festivedate} text={festivetext} location={location} />
      <Place />
      <Speaker speaker={speaker} />
      <Sponsor title={sponsorTitle} sponsor={sponsorimg} />
      <Organization organization={organizationimg} />
      <Gallery title={galleryTitle} img={galleryImg} />
      <Organizer organizer={organizer} />
      <Footer bg={footerbg} />
    </div>
  );
}

export default home;

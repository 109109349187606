import React from 'react';
import { Cloud } from '../Cloud';

export function Organizer(props) {
  let speaker = props.organizer.map((item) => {
    return (
      <div key={item.id} className="col-lg-6 col-md-6">
        <div className="speaker">
          <div className="img">
            <img src={item.img} alt={item.img} />
          </div>
          <div className="details">
            <h1 className="name">{item.name} </h1>
            <p className="desination">{item.job}</p>
            <p className="desination">{item.company} </p>
            <p className="desination">
              {' '}
              <a href={item.url_linkdin}>
                <i className={item.linkdin}></i>
              </a>{' '}
            </p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="shedule-part h-pb--200 h-p-relative" id="organizador">
      <Cloud color={'blue'} />
      <div className="overlay">
        <div className="title">
          <h1>Equipo organizador</h1>
        </div>
        <div className="container">
          <div className="row">{speaker}</div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import topics from '../../assets/images/topics.png';
import { Cloud } from '../Cloud';

export function About() {
  return (
    <div className="about-part h-pb--130 h-p-relative" id="about">
      <Cloud color={'blue'} />
      <div className="container p-0">
        <div className="row">
          <div className="col-sm-6 p-0  m-tb__30">
            <div className="about-left">
              <div className="text">
                <div>
                  <h1 className="text-title">Acerca del evento</h1>
                  <p className="para1">
                    <strong>AWS Community Day Perú 2023 </strong> es una conferencia cloud que se realiza anualmente y
                    cuyo propósito es compartir{' '}
                    <strong>
                      conocimientos, generar oportunidades laborales y establecer networking entre nuestros pares de la
                      industria
                    </strong>{' '}
                    a través una experiencia única y enriquecedora para nuestros asistentes.
                  </p>
                  <p className="para2">
                    Esta conferencia tendrá la participación de oradores internacionales así como la presencia de
                    expertos locales que pertenecen a las mejores empresas de tecnología y servicios de consultoría del
                    país.
                  </p>
                  {/* <h1 className="text-title m-top__30">¿Dónde y cuándo ?</h1>
                  <p className="para1">
                    <a href="https://goo.gl/maps/F7dXAbtqviYSJF5i6">Open PUCP</a> Boulevard Plaza Mantaro Pueblo Libre,
                    Lima - Perú. <br />
                    Sábado 15 de Abril de 2023 - 9:00 AM
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 p-0  m-tb__30">
            <div className="about-left">
              <div className="text">
                <div>
                  <h1 className="text-title">¿Qué esperar?</h1>
                  <p className="para1">
                    Durante el AWS Community Day Perú 2023 esperamos la asistencia de + 500 personas en formato
                    presencial y virtual, lo cual nos permitirá poder llegar a personas incluso fuera de Perú, dándonos
                    visibilidad dentro de la región.
                  </p>
                  <p className="para2">
                    El evento tendrá temas de las verticales más demandadas por la industria como{' '}
                    <strong>Devops, Serverless, Contenedores, Machine Learning, Seguridad, IoT</strong> y más donde cada
                    speaker nos compartira su expertis haciendo uso en la nube de AWS.
                  </p>
                  <img src={topics} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Cloud } from '../Cloud';

export function Organization(props) {
  let img = props.organization.map(function (item) {
    if (item.url) {
      return (
        <a className="logo-box" href={item.url} target="_blank">
          <img key={item.id} src={item.img} alt={item.img} />
        </a>
      );
    }
    return <img className="logo-box" key={item.id} src={item.img} alt={item.img} />;
  });

  return (
    <div className="organization-part h-pb--200 h-p-relative" id="organization">
      <Cloud color={'blue'} />

      <div className="title text-center p-t__60">
        <h1 className="m-t__30">ORGANIZACIÓN</h1>
      </div>
      <div className="container">
        <div className="img">{img}</div>
      </div>
    </div>
  );
}

import React from 'react';

function footer(props) {
  return (
    <section style={props.bg} className="footer-part">
      <div className="icon">
        <a href="https://www.linkedin.com/company/awsusergroupperu/" target="_blank" rel="noopener noreferrer">
          <i className="fa icon-aws_user_group" aria-hidden="true"></i>
        </a>
        <a
          href="https://linktr.ee/awsgirlsperu?utm_source=linktree_profile_share&ltsid=54338c90-bec2-476b-8fb7-934a94246f95"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa icon-aws-girls-peru" aria-hidden="true"></i>
        </a>
        <a href="https://www.linkedin.com/company/awssecuritylatam/" target="_blank" rel="noopener noreferrer">
          <i className="fa icon-awsugsec-latam" aria-hidden="true"></i>
        </a>
      </div>
      <div className="fotermenu">
        <ul>
          <li>
            <a href="#evento">Evento</a>
          </li>
          <li>
            <a href="#speakers">Speakers</a>
          </li>
          <li>
            <a href="#sponsors">Sponsors</a>
          </li>
          <li>
            <a href="#organizador">Organizadores</a>
          </li>
        </ul>
      </div>
      <div className="copy">
        <span>Hecho con ❤ por las comunidades de usuario de AWS en Perú 🇵🇪</span>
      </div>
    </section>
  );
}
export default footer;

import React from 'react';

function festivetext() {
  return (
    <div>
      <h1 className="text-title">El evento anual de Cloud Computing más GRANDE del Perú</h1>
      <p className="para1">
        Este evento es 100% organizado por un equipo de <strong>multiples comunidades Cloud en Perú</strong> con el
        respaldo de <strong>Amazon Web Services.</strong>
      </p>
    </div>
  );
}

export default festivetext;

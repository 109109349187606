import React from 'react';
import { Cloud } from '../Cloud';

function sponsor(props) {
  let sponsorList = props.sponsor.map(function (item) {
    if (item.url) {
      return (
        <a className="image logo-box" href={item.url} target="_blank">
          <img key={item.id} src={item.img} alt={item.img} />
        </a>
      );
    }

    return <img className="image logo-box" key={item.id} src={item.img} alt={item.img} />;
  });

  return (
    <div className="sponsor-part h-pb--150 h-p-relative" id="sponsors">
      <Cloud color={'grey'} />
      <div className="title text-center">
        <p>{props.title.subheading}</p>
        <h1>{props.title.heading}</h1>
      </div>
      <div className="container">
        <div className="img">{sponsorList}</div>
      </div>
    </div>
  );
}

export default sponsor;

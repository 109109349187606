/* eslint-disable */
import React from 'react';
function CountDown(props) {
  var countDownDate = new Date(props.count.date).getTime();

  var x = setInterval(function () {
    var now = new Date().getTime();

    var distance = countDownDate - now;

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    var day = document.getElementById('day');
    if (day) {
      document.getElementById('day').innerHTML = days;
      document.getElementById('hour').innerHTML = hours;
      document.getElementById('min').innerHTML = minutes;
      document.getElementById('sec').innerHTML = seconds;
    }
  }, 1000);

  return (
    <div className="counterdown">
      <div className="day">
        <p id="day"></p>
        <span>Días</span>
      </div>
      <div className="hour">
        <p id="hour"></p>
        <span>Horas</span>
      </div>
      <div className="min">
        <p id="min"></p>
        <span>Minutos</span>
      </div>
      <div className="sec">
        <p id="sec"></p>
        <span>Segundos</span>
      </div>
    </div>
  );
}

export default CountDown;

import white from '../assets/images/clouds/white-cloud.svg';
import orange from '../assets/images/clouds/orange-cloud.svg';
import blue from '../assets/images/clouds/blue-cloud.svg';
import grey from '../assets/images/clouds/grey-cloud.svg';

export const CLOUDS = {
  white,
  orange,
  blue,
  grey,
};

import React from 'react';

function festivelocation(props) {
  return (
    <div className="loaction">
      <div className="address">
        <div className="add">
          <img src={props.location[0].locationimg} alt={props.location[0].locationimg} />
        </div>
        <div className="add">
          <img className="addimg" src={props.location[1].addimg} alt={props.location[1].addimg} />
        </div>
        <div className="add">
          <img className="addimg" src={props.location[2].addimg} alt={props.location[2].addimg} />
        </div>
      </div>
    </div>
  );
}

export default festivelocation;

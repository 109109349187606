import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../registerbutton/navbtn';

function Navbar(props) {
  let navItem = props.name.map((item) => {
    return (
      <li key={item.id} className="nav-item">
        <a href={item.link}>{item.name}</a>
      </li>
    );
  });

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={props.logo} alt={props.logo} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {navItem}
            <a className="nav-wrapper-button" href="https://d1zuhtpqlys3bi.cloudfront.net/Agenda.pdf" target="_blank">
              <Button text="Agenda"></Button>
            </a>
            <a
              className="nav-wrapper-button ml__20 ext_link"
              href="https://aws.amazon.com/es/events/community-day/?developer-center-activities-cards.sort-by=item.additionalFields.startDateTime&developer-center-activities-cards.sort-order=asc"
              target="_blank"
            >
              🗓️ Próximos AWS Community Days
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import React from 'react';
import Slider from 'react-slick';
import Festivetext from './festivetext';
import Festivelocation from './festivelocation';
import { Cloud } from '../Cloud';

function festival(props) {
  const festiveSlider = {
    arrows: false,
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let Img = props.img.map((img) => {
    return <img key={img.id} src={img.img} alt={img.img} />;
  });

  return (
    <div className="festive-part h-pb--130 h-p-relative" id="evento">
      <Cloud color={'orange'} />
      <div className="container p-0">
        <div className="row mx-0">
          <div className="col-lg-7 m-top__30">
            <Slider {...festiveSlider}>{Img}</Slider>
          </div>

          <div className="col-lg-5 p-0  m-top__30">
            <div className="festive-left">
              <div className="text-img"></div>
              <div className="text">
                <Festivetext />
                <Festivelocation location={props.location} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default festival;

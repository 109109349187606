import logo from '../../assets/images/logo.png';
import Background from '../../assets/images/banner.png';
import Festiveimg from '../../assets/images/festive-img.png';
import Festivelocation from '../../assets/images/locationimg.png';
import Festivephone from '../../assets/images/address.png';
import Speakerbg from '../../assets/images/shedulebanner.png';
import Event1 from '../../assets/images/event1.png';
import Event2 from '../../assets/images/event2.png';
import Event3 from '../../assets/images/event3.png';
import Event4 from '../../assets/images/event4.png';
import Gallery1 from '../../assets/images/gallery1.png';
import Gallery2 from '../../assets/images/gallery2.png';
import Gallery3 from '../../assets/images/gallery3.png';
import Gallery4 from '../../assets/images/gallery4.png';
import Gallery5 from '../../assets/images/gallery5.png';
import Gallery6 from '../../assets/images/gallery6.png';
import Gallery7 from '../../assets/images/gallery8.png';
import Gallery8 from '../../assets/images/gallery9.png';
import Gallery9 from '../../assets/images/gallery10.png';
import Gallery10 from '../../assets/images/gallery11.png';
import Gallery11 from '../../assets/images/gallery12.png';
import Gallery12 from '../../assets/images/gallery13.png';
import Feedback1 from '../../assets/images/feedback1.png';
import Feedback2 from '../../assets/images/feedback2.png';
import Organization1 from '../../assets/images/organization1.png';
import Organization2 from '../../assets/images/organization2.png';
import Organization3 from '../../assets/images/organization3.png';
import Organization4 from '../../assets/images/organization4.png';
import Organization5 from '../../assets/images/organization5.png';
import Sponsor1 from '../../assets/images/sponsor1.png';
import Sponsor2 from '../../assets/images/sponsor2.png';
import Sponsor3 from '../../assets/images/sponsor3.png';
import Sponsor4 from '../../assets/images/sponsor4.png';
import Sponsor5 from '../../assets/images/sponsor5.png';
import Sponsor6 from '../../assets/images/sponsor6.png';
import Sponsor7 from '../../assets/images/sponsor7.png';
import Sponsor8 from '../../assets/images/sponsor8.png';
import Sponsor9 from '../../assets/images/sponsor9.png';
import Sponsor10 from '../../assets/images/sponsor10.png';
import Sponsor11 from '../../assets/images/sponsor11.png';
import Sponsor12 from '../../assets/images/sponsor12.png';
import Sponsor13 from '../../assets/images/sponsor13.png';
import Sponsor14 from '../../assets/images/sponsor14.png';
import Sponsorname from '../../assets/images/sponsorname1.png';
import Sponsorname2 from '../../assets/images/sponsorname2.png';
import Sponsorname3 from '../../assets/images/sponsorname3.png';
import Sponsorname4 from '../../assets/images/sponsorname4.png';
import Aboutbanner from '../../assets/images/aboutbanner.png';
import aws_ugsec_latam from '../../assets/images/aws_ugsec_latam.png';
import aws_ug_girls from '../../assets/images/aws_ug_girls.png';
import aws_ug_peru from '../../assets/images/aws_ug_peru.png';
import Err from '../../assets/images/error.png';
import banner1 from '../../assets/images/banner1.png';
import jose_yapur from '../../assets/images/jose_yapur.png';
import ivan_echegaray from '../../assets/images/ivan_echegaray.png';
import eli_fuentes from '../../assets/images/eli_fuentes.png';
import raul_hugo from '../../assets/images/raul_hugo.png';
import alfredo_alva from '../../assets/images/alfredo_alva.png';
import magali_pinto from '../../assets/images/magali_pinto.png';
import fernando_gamero from '../../assets/images/fernando_gamero.png';
import axel_pierola from '../../assets/images/axel_pierola.png';
import claudia_izquierdo from '../../assets/images/claudia_izquierdo.png';
import mayerli_romero from '../../assets/images/mayerli_romero.png';
import felipe_espinoza from '../../assets/images/felipe_espinoza.png';
import michael_rodrigo from '../../assets/images/michael_rodrigo.png';
import julian_arango from '../../assets/images/julian_arango.png';
import rossana_suarez from '../../assets/images/rossana_suarez.png';
import joe_huamani from '../../assets/images/joe_huamani.png';
import julio_mendoza from '../../assets/images/julio_mendoza.png';
import hans_verduguez from '../../assets/images/hans_verduguez.png';
import jurgen_guerra from '../../assets/images/jurgen_guerra.png';
import alfonso_torres from '../../assets/images/alfonso_torres.png';
import pedro_arrieta from '../../assets/images/pedro_arrieta.png';
import tony_trujillo from '../../assets/images/tony_trujillo.png';
import diego_fernandez from '../../assets/images/diego_fernandez.png';
import mar_ortiz from '../../assets/images/mar_ortiz.png';
import mario_inga from '../../assets/images/mario_inga.png';
import alvarofelipechavez from '../../assets/images/alvarofelipechavez.png';
import cecilia_vidal from '../../assets/images/cecilia_vidal.png';
import alanis_gonzalez from '../../assets/images/alanis_gonzalez.png';
import jenny_ramos from '../../assets/images/jenny_ramos.png';
import alisson_tibes from '../../assets/images/alisson_tibes.png';
import eddy_rodriguez from '../../assets/images/eddy_rodriguez.png';
import simon_cordova from '../../assets/images/simon_cordova.png';
import michaelt_inga from '../../assets/images/michaelt_inga.png';
import yonsy_solis from '../../assets/images/yonsy_solis.png';
import victor_cueva from '../../assets/images/victor_cueva.png';
import david_ugarte from '../../assets/images/david_ugarte.png';
import luis_felix from '../../assets/images/luis_felix.png';
import kattya_cuevas from '../../assets/images/kattya_cuevas.png';
import katherine_chauca from '../../assets/images/katherine_chauca.png';
import carolina_herrera from '../../assets/images/carolina_herrera.png';
import juan_rivera from '../../assets/images/juan_rivera.png';
import oscar_rodriguez from '../../assets/images/oscar_rodriguez.png';
import jair_tasayco from '../../assets/images/jair_tasayco.png';
import carlos_cruzado from '../../assets/images/carlos_cruzado.png';

import carlos_cortez from '../../assets/images/carlos_cortez.png';
import andre_cervantes from '../../assets/images/andre_cervantes.png';
import gerardo_castro from '../../assets/images/gerardo_castro.png';
import maricela_miranda from '../../assets/images/maricela_miranda.png';
import doris_manrique from '../../assets/images/doris_manrique.png';
import diana_alfaro from '../../assets/images/diana_alfaro.png';
import walter_levano from '../../assets/images/walter_levano.png';
import corrado_daly from '../../assets/images/corrado_daly.png';

const navitem = [
  {
    id: 2,
    name: 'Evento',
    link: '#evento',
  },
  {
    id: 3,
    name: 'Speakers',
    link: '#speakers',
  },
  {
    id: 4,
    name: 'Sponsors',
    link: '#sponsors',
  },
  {
    id: 5,
    name: 'Organizadores',
    link: '#organizador',
  },
];

// logo variable start
const image = logo;
// logo variable end
// banner image variable start

let bgimg = {
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
};
// banner image variable end

// banner text variable start
let bannerText = {
  subHeading: 'Lima, 15 de Abril 2023',
  heading: '',
  error: Err,
};
// banner text variable end

// banner countdown variable start
let bannerCountdown = {
  date: 'Apr 15, 2023 9:00:00',
};
// banner countdown variable end

// banner video button part start
let videobtn = {
  iconClassName: 'fa fa-play',
  text: 'Ver edición 2019',
};
// banner video button part end

// festive left part image variable start
let festiveimg = [
  {
    id: 1,
    img: Festiveimg,
  },
];
// festive left part image variable end

// festive date variable end
let festivedate = [
  {
    year: '2023',
  },
  {
    day: '15 Abril',
  },
  {
    city: 'Lima',
  },
];
// festive date variable end

//  festive text variable start

let festivetext = {
  heading: 'El evento anual de Cloud Computing más GRANDE del Perú',
  details: 'AWS Community Day Perú 2023 es una conferencia que se realiza anualmente y es dedicada para compartir ',
  bold: 'conocimientos, oportunidades y networking ',
  details_continue:
    'a través una experiencia única y enriquecedora para nuestros asistentes. Esta conferencia tendrá la participación de oradores internacionales así como la presencia de expertos locales que pertenecen a las mejores empresas de tecnología y servicios de consultoría del país.',
  details2: 'Este evento es 100% organizado por un equipo de ',
  volunteers: 'voluntarios de múltiples organizaciones Cloud en Lima',
  details3: ' con el respaldado de ',
  aws: 'Amazon Web Services.',
};
//  festive text variable end

// festive loaction variabl start
let location = [
  {
    locationimg: aws_ug_peru,
    text: '7382 Shirley Garden Avenue North-South Ozone Park,NewYork 11420',
  },
  {
    addimg: aws_ug_girls,
    addtext: '+880 1678965412 +880 1632145678 +880 1996321478',
  },
  {
    addimg: aws_ugsec_latam,
    addtext: '+880 1678965412 +880 1632145678 +880 1996321478',
  },
];
// festive loaction variabl end

// shedule variable start
let shedulebg = {
  backgroundImage: `url(${Speakerbg})`,
  backgroundSize: 'cover',
};

let title = {
  subheading: 'Time Table & Speaking People',
  heading: 'Schedule & Speakers',
};
let speaker = [
  {
    id: 1,
    img: jose_yapur,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Jose Yapur',
    designation: 'Sr. Developer Advocate 🇵🇪',
    company: 'Amazon Web Services',
    isAWS: true,
    twiter: 'fa fa-twitter',
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/joseyapur/',
  },
  {
    id: 2,
    img: magali_pinto,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Magali Pinto',
    designation: 'Manager, Solutions Architect 🇵🇪',
    company: 'Amazon Web Services',
    isAWS: true,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/magalipintof/',
  },
  {
    id: 3,
    img: raul_hugo,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Raul Hugo',
    designation: 'Sr. Solutions Architect 🇵🇪',
    company: 'Amazon Web Services',
    isAWS: true,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/raulhugo/',
  },
  {
    id: 4,
    img: alfredo_alva,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Alfredo Alva',
    designation: 'President at Cloud Security Alliance 🇵🇪',
    company: 'Peru Chapter',
    isAWS: false,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/aalval/',
  },
  {
    id: 5,
    img: eli_fuentes,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Elizabeth Fuentes',
    designation: 'Developer Advocate 🇨🇱',
    company: 'Amazon Web Services',
    isAWS: true,
    twiter: 'fa fa-twitter',
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/lizfue/',
  },
  {
    id: 6,
    img: fernando_gamero,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Fernando Gamero',
    designation: 'Cloud Security Solutions Architect 🇵🇪',
    company: 'Palo Alto Networks',
    isAWS: false,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/fernandogamerog/',
  },
  {
    id: 7,
    img: ivan_echegaray,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Ivan Echegaray',
    designation: 'DevOps Senior 🇵🇪',
    company: '',
    isAWS: false,
    twiter: 'fa fa-twitter',
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/ivanechegaray/',
  },
  {
    id: 8,
    img: doris_manrique,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Doris Manrique',
    designation: 'Líder AWS Girls Perú 🇵🇪',
    company: '',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/dorismanrique/',
  },
  {
    id: 9,
    img: diego_fernandez,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Diego Fernandez',
    designation: 'Senior Solutions Architect 🇵🇪',
    company: 'Amazon Web Services',
    isAWS: true,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/diegofernandezayala/',
  },
  {
    id: 10,
    img: felipe_espinoza,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Felipe Espinoza',
    designation: 'Jefe Infraestructura y Cloud 🇨🇱',
    company: 'CJG Consultores',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/felipe-espinoza-oviedo/',
  },
  {
    id: 11,
    img: claudia_izquierdo,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Claudia Izquierdo',
    designation: 'Solutions Architect 🇬🇹',
    company: 'Amazon Web Services',
    isAWS: true,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/claudia-izquierdo-salazar/',
  },
  {
    id: 12,
    img: michael_rodrigo,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Michael Rodrigo',
    designation: 'Director de Operaciones y Proyectos SAP 🇨🇱',
    company: 'CJG Consultores',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/michaelrodrigo/',
  },
  {
    id: 13,
    img: julio_mendoza,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Julio Mendoza',
    designation: 'Teacher Assistant 🇵🇪',
    company: 'PUCP',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/juliomendozah/',
  },
  {
    id: 14,
    img: maricela_miranda,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Maricela Miranda',
    designation: 'Líder AWS Girls Perú 🇵🇪',
    company: '',
    isAWS: false,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/maricela-miranda-ch%C3%A1vez/',
  },
  {
    id: 15,
    img: julian_arango,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Julian Arango',
    designation: 'Territory Sales Manager 🇨🇴',
    company: 'Fluid Attacks',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/jarangoo/',
  },
  {
    id: 16,
    img: joe_huamani,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Joe Huamaní',
    designation: 'Asistente de investigación de Inteligencia Artificial 🇵🇪',
    company: 'PUCP',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/joe-natan-huamani-malca-621514134/',
  },
  {
    id: 17,
    img: diana_alfaro,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Diana Alfaro',
    designation: 'AWS re/Start Instructor 🇵🇪',
    company: '',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/dianaalfarobazan/',
  },
  {
    id: 18,
    img: andre_cervantes,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Andre Cervantes',
    designation: 'Cybersecurity Cloud 🇵🇪',
    company: 'Globant',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/andr%C3%A9-cervantes-villavicencio-4ba27225/',
  },
  {
    id: 19,
    img: gerardo_castro,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Gerardo Castro',
    designation: 'Security Solutions Architect 🇵🇪',
    company: 'Caleidos',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/gerardokaztro/',
  },
  {
    id: 20,
    img: mar_ortiz,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Mar Ortiz',
    designation: 'Arquitecta de soluciones 🇨🇴',
    company: 'Save the Children International',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/mar31orvel/',
  },
  {
    id: 21,
    img: walter_levano,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Walter Lévano',
    designation: 'Sub Gerente de Infra y Ops TI 🇵🇪',
    company: 'La Positiva Seguros',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/walter-levano-mba-devops-itil-cobit-cloudf-04a27553/',
  },
  {
    id: 22,
    img: jurgen_guerra,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Jürgen Guerra',
    designation: 'Senior Data Engineer 🇵🇪',
    company: 'Morris & Opazo',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/jurgen-guerra/',
  },
  {
    id: 23,
    img: jenny_ramos,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Jenny Ramos',
    designation: 'Product Owner 🇵🇪',
    company: 'BCP',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/jennyramosa/',
  },
  {
    id: 24,
    img: alfonso_torres,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Alfonso Torres',
    designation: 'Solutions Architect 🇵🇪',
    company: 'La Positiva Seguros',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/atorr33/',
  },
  {
    id: 25,
    img: pedro_arrieta,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Pedro Arrieta',
    designation: 'Cloud Engineer 🇵🇪',
    company: 'Globant',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/pedro-arrieta/',
  },
  {
    id: 26,
    img: alanis_gonzalez,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Alanis González',
    designation: 'Cloud & Automation Intern 🇵🇪',
    company: 'Entel Perú',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/alanisgonzalez/',
  },
  {
    id: 27,
    img: tony_trujillo,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Tony Trujillo',
    designation: 'Arquitecto de datos 🇵🇪',
    company: 'IDATHA',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/tonytec/',
  },
  {
    id: 28,
    img: axel_pierola,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Axel Pierola',
    designation: 'AWS Community Builder & Serverless Senior 🇵🇪',
    company: '',
    isAWS: false,
    linkdin: 'fa fa-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/axlpierola/',
  },
  {
    id: 29,
    img: cecilia_vidal,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Cecilia Vidal',
    designation: 'CEO & Founder 🇵🇪',
    company: 'Anicca Lab',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/cecilia-vidal-623854b/',
  },
  {
    id: 30,
    img: alvarofelipechavez,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Álvaro Felipe Chávez',
    designation: 'CEO 🇵🇪',
    company: 'EDteam',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/alvarofelipechavez/',
  },
  {
    id: 31,
    img: mario_inga,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Mario Inga',
    designation: 'Senior DevOps Engineer 🇵🇪',
    company: 'redbee',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/mario21ic/',
  },
  {
    id: 32,
    img: kattya_cuevas,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Kattya Cuevas',
    designation: 'Software Developer 🇵🇪',
    company: 'Shopify',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/kattyacuevas/',
  },
  {
    id: 33,
    img: hans_verduguez,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Hans Verduguez',
    designation: 'Regional BDE Cloud Services 🇧🇴',
    company: 'SoftwareOne ',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/hans-verduguez/',
  },
  {
    id: 34,
    img: alisson_tibes,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Alisson Tibes',
    designation: 'Cloud Security Architect 🇧🇷',
    company: 'Check Point Software Technologies, Ltd.',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/alissontibes/',
  },
  {
    id: 35,
    img: katherine_chauca,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Katherine Chauca',
    designation: 'Community Builder 🇵🇪',
    company: 'Platzi',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/katherinechauca/',
  },
  {
    id: 36,
    img: eddy_rodriguez,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Eddy Rodriguez',
    designation: 'Gerente de Operaciones de Arquitectura y Nube Pública 🇵🇪',
    company: 'Canvia',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/eddyrodriguezulloa/',
  },
  {
    id: 37,
    img: simon_cordova,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Simón Córdova',
    designation: 'Senior Solutions Architect 🇵🇪',
    company: 'Amazon Web Services',
    isAWS: true,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/scordovar/',
  },
  {
    id: 38,
    img: carolina_herrera,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Carolina Herrera',
    designation: 'Senior DevOps Engineer 🇵🇪',
    company: 'Globant',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/carolinahm/',
  },
  {
    id: 39,
    img: michaelt_inga,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Michaelt Inga',
    designation: 'DevOps Engineer 🇵🇪',
    company: 'NTTDATA',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/michaelt-inga-cahuana-518117163/',
  },
  {
    id: 40,
    img: yonsy_solis,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Yonsy Solis',
    designation: 'SRE/DevOps Senior Architect 🇵🇪',
    company: 'Andela',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/yonsy/',
  },
  {
    id: 41,
    img: victor_cueva,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Victor Cueva',
    designation: 'CTO 🇵🇪',
    company: 'Amber',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/victoralin10/',
  },
  {
    id: 42,
    img: david_ugarte,
    time: '09:00 A.M - 11:00 A.M',
    name: 'David Ugarte',
    designation: 'Senior Solutions Architect 🇵🇪',
    company: 'Amazon Web Services',
    isAWS: true,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/david-ugarte/',
  },
  {
    id: 43,
    img: luis_felix,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Luis Felix',
    designation: 'Director, Growth & Digital Transformation 🇵🇪',
    company: 'En Estado Beta',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/luisfelix1983/',
  },
  {
    id: 44,
    img: juan_rivera,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Juan Rivera',
    designation: 'Apoderado de BI y Data Analytics 🇵🇪',
    company: 'La Positiva Seguros',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/juan-zacarias-rivera-ramirez/',
  },
  {
    id: 45,
    img: oscar_rodriguez,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Oscar Rodriguez',
    designation: 'FinOps Growth Lead LATAM 🇨🇴',
    company: 'SoftwareONE',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/oscar-javier-rodriguez-cortes-24541b19/',
  },
  {
    id: 46,
    img: jair_tasayco,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Jair Tasayco',
    designation: 'Arquitecto de aplicaciones 🇵🇪',
    company: 'Corporación Grupo Romero',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/jair-tasayco/',
  },
  {
    id: 47,
    img: carlos_cruzado,
    time: '09:00 A.M - 11:00 A.M',
    name: 'Carlos Cruzado',
    designation: 'AWS Community Builder 🇵🇪',
    company: '',
    isAWS: false,
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/ccruzado/',
  },
];

let organizer = [
  {
    id: 1,
    img: andre_cervantes,
    name: 'André Cervantes',
    job: 'Cybersecurity Cloud',
    company: 'Globant',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/andr%C3%A9-cervantes-villavicencio-4ba27225/',
  },
  {
    id: 2,
    img: gerardo_castro,
    name: 'Gerardo Castro',
    job: 'Security Solutions Architect',
    company: 'Caleidos',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/gerardokaztro/',
  },
  {
    id: 3,
    img: diana_alfaro,
    name: 'Diana Alfaro',
    job: 'FrontEnd Developer',
    company: 'AWS re/Start Instructor',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/dianaalfarobazan/',
  },
  {
    id: 4,
    img: doris_manrique,
    name: 'Doris Manrique',
    job: 'Líder AWS Girls Perú',
    company: '',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/dorismanrique/',
  },
  {
    id: 5,
    img: maricela_miranda,
    name: 'Maricela Miranda',
    job: 'Cloud Sales Engineer AWS',
    company: 'Líder AWS Girls Perú',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/maricela-miranda-ch%C3%A1vez/',
  },
  {
    id: 6,
    img: walter_levano,
    name: 'Walter Lévano',
    job: 'Sub Gerente de Infra y Ops TI',
    company: 'La Positiva Seguros',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/walter-levano-mba-devops-itil-cobit-cloudf-04a27553/',
  },
  {
    id: 7,
    img: corrado_daly,
    name: 'Corrado Daly',
    job: 'Profesional Ingeniero',
    company: 'Pontificia Universidad Catolica del Peru',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/corrado-daly-0bab1433/',
  },
  {
    id: 8,
    img: carlos_cortez,
    name: 'Carlos Cortez',
    job: 'Cloud Architect',
    company: 'CyberCX',
    linkdin: 'fa fa-linkedin organizer-linkedin',
    url_linkdin: 'https://www.linkedin.com/in/carloscortezcloud/',
  },
];

// shedule variable end

// upcoming variable start
let upcomingtitle = {
  subheading: 'fututure events',
  heading: 'UPCOMING  EVENTS',
};

let event = [
  {
    id: 1,
    img: Event1,
    title: 'Wordpress Day Long Bootcamp',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 2,
    img: Event2,
    title: 'Graphic Design Seminar for 3 Days',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 3,
    img: Event3,
    title: 'laravel Framework Workshop (Day Long)',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 4,
    img: Event4,
    title: 'UI/UX Bootcamp - 2020',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 5,
    img: Event2,
    title: 'Graphic Design Seminar for 3 Days',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 6,
    img: Event3,
    title: 'laravel Framework Workshop (Day Long)',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 7,
    img: Event1,
    title: 'Wordpress Day Long Bootcamp',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 8,
    img: Event2,
    title: 'Graphic Design Seminar for 3 Days',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 9,
    img: Event3,
    title: 'laravel Framework Workshop (Day Long)',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 10,
    img: Event4,
    title: 'UI/UX Bootcamp - 2020',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 11,
    img: Event2,
    title: 'Graphic Design Seminar for 3 Days',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 12,
    img: Event3,
    title: 'laravel Framework Workshop (Day Long)',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 13,
    img: Event1,
    title: 'Wordpress Day Long Bootcamp',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 14,
    img: Event2,
    title: 'Graphic Design Seminar for 3 Days',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 15,
    img: Event3,
    title: 'laravel Framework Workshop (Day Long)',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 16,
    img: Event4,
    title: 'UI/UX Bootcamp - 2020',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 17,
    img: Event2,
    title: 'Graphic Design Seminar for 3 Days',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
  {
    id: 18,
    img: Event3,
    title: 'laravel Framework Workshop (Day Long)',
    locationIcon: Festivelocation,
    address: '7382 Shirley Garden Avenue North-South Ozone Park,',
    phone: Festivephone,
    number: '+880 1678965412 +880 1632145678',
  },
];

// upcoming variable end

// gallery variable start
let galleryTitle = {
  subheading: 'photos of event',
  heading: 'Event Gallery',
};

let galleryImg = [
  {
    id: 1,
    img: Gallery1,
  },
  {
    id: 2,
    img: Gallery2,
  },
  {
    id: 3,
    img: Gallery3,
  },
  {
    id: 4,
    img: Gallery4,
  },
  {
    id: 5,
    img: Gallery5,
  },
  {
    id: 6,
    img: Gallery6,
  },
  // {
  //   id: 7,
  //   img: Gallery7,
  // },
  // {
  //   id: 8,
  //   img: Gallery8,
  // },
  // {
  //   id: 9,
  //   img: Gallery9,
  // },
  // {
  //   id: 10,
  //   img: Gallery10,
  // },
  // {
  //   id: 11,
  //   img: Gallery11,
  // },
  // {
  //   id: 12,
  //   img: Gallery12,
  // },
];
// gallery variable end

// event variable start

let feedbackTitle = {
  subheading: 'positive reviews',
  heading: 'Events Feedback',
};

let feedback = [
  {
    id: 1,
    details:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    img: Feedback1,
    icon: 'fa fa-star',
    name: 'Jonathon Reverse',
    designation: 'Event Sponsor',
  },
  {
    id: 2,
    details:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    img: Feedback2,
    icon: 'fa fa-star',
    name: 'Jerinno Lopez',
    designation: 'Event Sponsor',
  },
  {
    id: 3,
    details:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    img: Feedback1,
    icon: 'fa fa-star',
    name: 'Jonathon Reverse',
    designation: 'Event Sponsor',
  },
];

// event variable end

let organizationimg = [
  {
    id: 1,
    img: Organization3,
    url: 'https://www.linkedin.com/company/awsusergroupperu/',
  },
  {
    id: 2,
    img: Organization4,
    url: 'https://linktr.ee/awsgirlsperu',
  },
  {
    id: 3,
    img: Organization5,
    url: 'https://www.linkedin.com/company/awssecuritylatam/',
  },
  {
    id: 4,
    img: Organization1,
    url: 'https://www.pucp.edu.pe/',
  },
  {
    id: 5,
    img: Organization2,
  },
];

// pricing part variable start

let pricingTitle = {
  subheading: 'entry pass',
  heading: 'Pricing & Get Ticket',
};

let pricingPlane = [
  {
    id: 1,
    name: 'Regular Plan',
    price: '$29.99',
    facility: ['A+ Class Seat', 'Free WiFi', 'Unlimited Coffee', 'Lunch', 'Workshop', 'Stationary', 'X', 'X'],
  },
  {
    id: 2,
    name: 'Premium Plan',
    price: '$59.99',
    facility: ['A+ Class Seat', 'Free WiFi', 'Unlimited Coffee', 'Lunch', 'Workshop', 'Stationary', 'X', 'X'],
  },
  {
    id: 3,
    name: 'Platinum Plan',
    price: '$99.99',
    facility: ['A+ Class Seat', 'Free WiFi', 'Unlimited Coffee', 'Lunch', 'Workshop', 'Stationary', 'X', 'X'],
  },
];

let selectplan = [
  {
    id: 1,
    plan: 'Plan1',
  },
  {
    id: 2,
    plan: 'Plan2',
  },
  {
    id: 3,
    plan: 'Plan3',
  },
  {
    id: 4,
    plan: 'Plan4',
  },
  {
    id: 5,
    plan: 'Plan5',
  },
  {
    id: 6,
    plan: 'Plan6',
  },
];

// pricing part variable end

// sponsor part variable start
let sponsorTitle = {
  subheading: 'Nuestros',
  heading: 'SPONSORS',
};

let sponsorimg = [
  {
    id: 1,
    img: Sponsor1,
  },
  {
    id: 2,
    img: Sponsor5,
    url: 'https://www.paloaltonetworks.com/prisma/cloud',
  },
  {
    id: 3,
    img: Sponsor2,
    url: 'https://fluidattacks.com/',
  },
  {
    id: 13,
    img: Sponsor13,
    url: 'https://www.minsait.com/es',
  },
  {
    id: 4,
    img: Sponsor9,
    url: 'https://cjgconsultores.com/',
  },
  {
    id: 5,
    img: Sponsor10,
    url: 'https://www.checkpoint.com/es/',
  },
  {
    id: 6,
    img: Sponsor11,
    url: 'https://www.softwareone.com/es-pe',
  },
  {
    id: 7,
    img: Sponsor7,
    url: 'https://amber.pe/',
  },
  {
    id: 14,
    img: Sponsor14,
    url: 'https://bigcheese.pe/home',
  },
  {
    id: 8,
    img: Sponsor8,
    url: 'https://ed.team/',
  },
  {
    id: 9,
    img: Sponsor6,
  },
  {
    id: 10,
    img: Sponsor12,
    url: 'https://estrategicaoi.com/',
  },
  {
    id: 11,
    img: Sponsor3,
    url: 'https://www.linkedin.com/company/quiputec/',
  },
  {
    id: 12,
    img: Sponsor4,
    url: 'https://www.youtube.com/@imperiocloud',
  },
];
// sponsor part variable end

// footer part variable start

let footerbg = {
  // backgroundImage: `url(${Footerbg})`,
  // background: '#1a1a1a',
  background: '#232F3E',
  backgroundSize: 'cover',
};

// footer part variable end

// about page variable start
let aboutbgimg = {
  backgroundImage: `url(${Aboutbanner})`,
  backgroundSize: 'cover',
};

// about page variable end

// sponsor page variable start

let sponsorname = [
  {
    id: 1,
    level: 'most precious',
    title: 'Title Sponsor',
    img: Sponsorname,
  },
  {
    id: 2,
    level: '2nd precious',
    title: 'Signature Sponsor',
    img: Sponsorname2,
  },
  {
    id: 3,
    level: 'micro supporter',
    title: 'Golden Sponsorr',
    img: Sponsorname3,
  },
  {
    id: 4,
    level: 'micro supporter',
    title: 'Silver Sponsor',
    img: Sponsorname4,
  },
];

// sponsor page variable end

export {
  navitem,
  image,
  bgimg,
  bannerText,
  bannerCountdown,
  videobtn,
  festiveimg,
  festivedate,
  festivetext,
  location,
  shedulebg,
  speaker,
  organizer,
  title,
  upcomingtitle,
  event,
  galleryTitle,
  galleryImg,
  feedback,
  feedbackTitle,
  organizationimg,
  pricingTitle,
  pricingPlane,
  selectplan,
  sponsorTitle,
  sponsorimg,
  footerbg,
  aboutbgimg,
  sponsorname,
};

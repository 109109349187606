import React from 'react';
import { Button } from '../registerbutton/navbtn';
import CountDown from './bannerCountDown';
import Vedionbtn from './vediobtn';
import { Cloud } from '../Cloud';

import communityLogo from '../../assets/images/CommunityDay_logo.png';

function banner(props) {
  return (
    <section style={props.bg} className="banner-part banner-background">
      <Cloud color={'grey'} />
      <div className="overlay" id="home">
        <div className="container p-0 counter">
          <img src={communityLogo} className="communityLogo" alt="" />
          <p className="m-top__30">
            <strong>Lima, 15 de Abril 2023</strong>
          </p>
          <div className="row m-top__30">
            <div className="col-lg-9">
              <CountDown count={props.countdown} />
            </div>
            <div className="col-lg-3 p-0">
              <Vedionbtn videobtn={props.videobtn} />
            </div>
          </div>
          <div className="row m-top__30">
            <a
              className="m--auto wrapper-button"
              href="https://www.eventbrite.com.pe/e/entradas-aws-community-day-lima-2023-558412114667"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button text="REGÍSTRATE AQUÍ"></Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default banner;

import React from 'react';
import Slider from 'react-slick';
import { Cloud } from '../Cloud';

export function Gallery(props) {
  let img = props.img.map((item) => {
    return (
      <div className="img" key={item.id}>
        <img src={item.img} img={item.img} />
      </div>
    );
  });

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="gallery-part h-pb--200 h-p-relative">
      <Cloud color={'grey'} />
      <div className="title text-center"></div>
      <Slider {...settings}>{img}</Slider>
    </div>
  );
}
